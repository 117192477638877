<template>
    <v-app>
        <v-main>
            <v-navigation-drawer dark v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
                <v-list>
                    <v-list-item>
                        <v-list-item-avatar>
                            <v-img :src="usuarioGlobal.imagen"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="title" v-text="usuarioGlobal.correo"></v-list-item-title>
                            <v-list-item-subtitle v-text="usuarioGlobal.tipoPerfil"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>

                <v-list dense>
                    <v-list-item :to="{ name: 'inicioPanel' }">
                        <v-list-item-icon>
                            <v-icon>mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Inicio</v-list-item-title>
                    </v-list-item>

                    <v-list-group v-show="validarPermiso('roles.listado') || validarPermiso('usuarios.listado')" prepend-icon="mdi-lock" no-action>
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>Seguridad</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item v-show="validarPermiso('roles.listado')" :to="{ name: 'rolesPanel' }">
                            <v-list-item-title>Roles</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-lock</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('usuarios.listado')" :to="{ name: 'usuariosPanel' }">
                            <v-list-item-title>Usuarios</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-account</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list-group>

                    <v-list-group
                        v-show="
                            validarPermiso('empresa.index') ||
                            validarPermiso('seo.index') ||
                            validarPermiso('paginas.listado') ||
                            validarPermiso('blogs.listado') ||
                            validarPermiso('popup-manager.listado') ||
                            validarPermiso('suscripciones.listado')
                        "
                        prepend-icon="mdi-cogs"
                        no-action
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>Configuracion</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item v-show="validarPermiso('empresa.index')" :to="{ name: 'empresaPanel' }">
                            <v-list-item-title>Empresa</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-factory</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('seo.index')" :to="{ name: 'seoPanel' }">
                            <v-list-item-title>Seo</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-robot</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('paginas.listado')" :to="{ name: 'paginasPanel' }">
                            <v-list-item-title>Paginas</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-image-area</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('popup-manager.listado')" :to="{ name: 'popupManagerPanel' }">
                            <v-list-item-title>Popup Manager</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-image</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('blogs.listado')" :to="{ name: 'blogsPanel' }">
                            <v-list-item-title>Blogs</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-blogger</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('suscripciones.listado')" :to="{ name: 'suscripcionesPanel' }">
                            <v-list-item-title>Suscripciones</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-star</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list-group>

                    <v-list-group
                        v-show="
                            validarPermiso('lunas.listado') ||
                            validarPermiso('clases.listado') ||
                            validarPermiso('categorias.listado') ||
                            validarPermiso('productos.listado') ||
                            validarPermiso('tag.listado') ||
                            validarPermiso('forma.listado') ||
                            validarPermiso('marca.listado') || 
                            validarPermiso('lc_tipo.listado')
                        "
                        prepend-icon="mdi-shopping"
                        no-action
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>Catalogo</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item v-show="validarPermiso('lunas.listado')" :to="{ name: 'lunasPanel' }">
                            <v-list-item-title>Lunas</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-window-restore</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item :to="{ name: 'lunasColoresPanel' }">
                            <v-list-item-title>Colores de lunas</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-palette</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item :to="{ name: 'productosColoresPanel' }">
                            <v-list-item-title>Colores de productos</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-palette</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('clases.listado')" :to="{ name: 'clasesPanel' }">
                            <v-list-item-title>Clases</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-book-variant</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('categorias.listado')" :to="{ name: 'categoriasPanel' }">
                            <v-list-item-title>Categorias</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-shape</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('productos.listado')" :to="{ name: 'productosPanel' }">
                            <v-list-item-title>Productos</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-glasses</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('tag.listado')" :to="{ name: 'tagPanel' }">
                            <v-list-item-title>Etiquetas</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-book-variant</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('forma.listado')" :to="{ name: 'formaPanel' }">
                            <v-list-item-title>Formas</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-book-variant</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('productos.listado')" :to="{ name: 'lentesContactoPanel' }">
                            <v-list-item-title>Lentes de contacto</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-glasses</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                        
                        <v-list-item v-show="validarPermiso('marca.listado')" :to="{ name: 'marcaPanel' }">
                            <v-list-item-title>Marcas</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-book-variant</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('lc_tipo.listado')" :to="{ name: 'lc_tipoPanel' }">
                            <v-list-item-title>Tipo de L.C.</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-book-variant</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list-group>

                    <v-list-group
                        v-show="
                            validarPermiso('clientes.listado') ||
                            validarPermiso('ventas.listado') ||
                            validarPermiso('cupones.listado') ||
                            validarPermiso('pedidosPersonalizados.listado') ||
                            validarPermiso('reservas.listado')
                        "
                        prepend-icon="mdi-currency-usd"
                        no-action
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>Ventas_new</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item v-show="validarPermiso('clientes.listado')" :to="{ name: 'clientesPanel' }">
                            <v-list-item-title>Clientes</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-account</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                        
                        <v-list-item v-show="validarPermiso('ventas.listado')" :to="{ name: 'ventasPanel' }">
                            <v-list-item-title>Ventas</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-store</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('ventas.listado')" :to="{ name: 'ventasFinalizadasPanel' }">
                            <v-list-item-title>Ventas Finalizadas</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-store</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('ventas.listado')" :to="{ name: 'ventasCanceladasPanel' }">
                            <v-list-item-title>Ventas Canceladas</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-store</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('cupones.listado')" :to="{ name: 'cuponesPanel' }">
                            <v-list-item-title>Cupones</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-ticket-percent</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('cupones.listado')" :to="{ name: 'pedidoDescuentoPanel' }">
                            <v-list-item-title>Pedido de descuento</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-ticket-percent</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('pedidosPersonalizados.listado')" :to="{ name: 'pedidoPersonalizadoPanel' }">
                            <v-list-item-title>Pedidos Personalizados</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-account-edit</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('reservas.listado')" :to="{ name: 'reservasPanel' }">
                            <v-list-item-title>Reservas</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-ticket-account</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list-group>

                    
                    <v-list-group
                        v-show="
                            validarPermiso('ventas-2022.listado') 
                        "
                        prepend-icon="mdi-currency-usd"
                        no-action
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>Ventas_old</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item v-show="validarPermiso('ventas-2022.listado')" :to="{ name: 'ventasPanel-2022' }">
                            <v-list-item-title>Ventas</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-store</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('ventas-2022.listado')" :to="{ name: 'ventasFinalizadasPanel-2022' }">
                            <v-list-item-title>Ventas Finalizadas</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-store</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-show="validarPermiso('ventas-2022.listado')" :to="{ name: 'ventasCanceladasPanel-2022' }">
                            <v-list-item-title>Ventas Canceladas</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-store</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list-group>

                    

                    <v-list-group v-show="validarPermiso('opticas.listado')" prepend-icon="mdi-eye" no-action>
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>Examen</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item v-show="validarPermiso('opticas.listado')" :to="{ name: 'opticasPanel' }">
                            <v-list-item-title>Opticas</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-home-city</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list-group>

                    <v-list-group v-show="validarPermiso('probadorVirtual.listado')" prepend-icon="mdi-file-chart" no-action>
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>Reportes</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item v-show="validarPermiso('probadorVirtual.listado')" :to="{ name: 'probadorVirtualPanel' }">
                            <v-list-item-title>Probador Virtual</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-image-move</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list-group>

                    <v-list-item v-show="validarPermiso('listaNegra.listado')" :to="{ name: 'listaNegraPanel' }">
                        <v-list-item-icon>
                            <v-icon>mdi-image-filter-black-white</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Lista Negra</v-list-item-title>
                    </v-list-item>

                    <!--<v-list-item @click="cerrarSesion()" :disabled="btnCerrarSesion">
                    <v-list-item-action>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Cerrar sesión</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>-->
                </v-list>
            </v-navigation-drawer>

            <v-app-bar class="info darken-1" dark :clipped-left="$vuetify.breakpoint.lgAndUp" app>
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                <v-toolbar-title class="ml-0 pl-4">
                    <span class="hidden-sm-and-down" v-text="empresa.nombre"></span>
                </v-toolbar-title>
                <!--<v-text-field
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
            class="hidden-sm-and-down"
          ></v-text-field>-->
                <v-spacer></v-spacer>

                <v-switch @change="cambiarThema" class="mt-5 mr-10" v-model="modoOscuro" label="Modo oscuro"></v-switch>

                <!--<v-menu :nudge-width="200">

                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-badge color="error" content="2" overlap="" left="">
                            <v-icon>mdi-bell</v-icon>
                        </v-badge>
                    </v-btn>
                </template>

                <v-card>
                    <v-list>
                        <v-list-item link>

                            <v-list-item-icon>
                                <v-icon>mdi-bell-ring</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>John Leider</v-list-item-title>
                                <v-list-item-subtitle>Founder of Vuetify.js</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>

                        <v-list-item link>

                            <v-list-item-icon>
                                <v-icon>mdi-bell-ring</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>John Leider</v-list-item-title>
                                <v-list-item-subtitle>Founder of Vuetify.js</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                    </v-list>

                </v-card>

            </v-menu>-->

                <v-menu :nudge-width="200">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon large v-bind="attrs" v-on="on">
                            <v-avatar size="32px" item>
                                <v-img :src="usuarioGlobal.imagen"></v-img>
                            </v-avatar>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-img :src="usuarioGlobal.imagen"></v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-text="usuarioGlobal.correo"></v-list-item-title>
                                    <v-list-item-subtitle v-text="usuarioGlobal.tipoPerfil"></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn :to="{ name: 'PerfilUsuarioPanel' }" color="info" block="">
                                <v-icon right="">mdi-account</v-icon>
                                Mi perfil
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn :disabled="btnCerrarSesion" color="error" block="" @click="cerrarSesion">
                                <v-icon right="">mdi-logout</v-icon>
                                Cerrar Sesión
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-app-bar>

            <router-view></router-view>

            <div style="margin-top: 50px">
                <v-footer absolute="" padless="" class="font-weight-medium">
                    <v-col class="text-center" cols="12"> {{ new Date().getFullYear() }} — <strong v-text="empresa.nombre"></strong> </v-col>
                </v-footer>
            </div>
        </v-main>
    </v-app>
</template>

<script>
    export default {
        data() {
            return {
                usuarioGlobal: {
                    correo: "",
                    tipoPerfil: "",
                    imagen: require("../../assets/user_no_photo.png"),
                },

                empresa: {
                    nombre: "Optimania",
                },
                modoOscuro: false,
                drawer: true,
                btnCerrarSesion: false,
            };
        },

        methods: {
            datosUsuario() {
                let usuario = this.$session.get("usuario");
                this.usuarioGlobal.correo = usuario.correo;
                this.usuarioGlobal.tipoPerfil = usuario.tipoPerfil;
            },

            cerrarSesion() {
                this.btnCerrarSesion = true;

                this.axios
                    .post("api/panel/login/cerrarSesion")
                    .then((res) => {
                        this.$toasted.info(res.data, {
                            icon: "mdi-logout",
                        });
                        this.$session.clear();
                        this.$vuetify.theme.dark = false;
                        this.$router.push({
                            name: "loginPanel",
                        });
                    })
                    .catch((err) => {
                        this.btnCerrarSesion = false;
                        console.error(err.response);
                    });
            },

            cambiarThema() {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
                this.$session.set("usarModoOscuro", this.$vuetify.theme.dark.toString());
            },

            ocultarMenu() {
                if (this.$vuetify.breakpoint.mdAndDown) {
                    this.drawer = false;
                } else {
                    this.drawer = true;
                }
            },
        },

        mounted() {
            const theme = this.$session.get("usarModoOscuro");
            if (theme) {
                if (theme === "true") {
                    this.$vuetify.theme.dark = true;
                    this.modoOscuro = true;
                } else {
                    this.$vuetify.theme.dark = false;
                    this.modoOscuro = false;
                }
            }
        },

        created() {
            this.datosUsuario();
            this.ocultarMenu();
        },
    };
</script>
<style>
    /*.v-input:not(.v-textarea) > .v-input__control {
      height: 30px !important;
    }*/
</style>
